import * as bootstrap from "bootstrap";
import $ from "jquery";

import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

// Ensure LightGallery CSS is included
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-zoom.css';

document.addEventListener('DOMContentLoaded', function() {

    document.querySelectorAll('.view-property-details').forEach(function(button) {
        button.addEventListener('click', function() {
            var propertyId = button.getAttribute('data-property-id');
            fetch(`/property/${propertyId}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.text();
                })
                .then(data => {
                    document.getElementById('propertyModalContent').innerHTML = data;
                    var propertyModal = new bootstrap.Modal(document.getElementById('propertyModal'), {
                        keyboard: false
                    });
                    propertyModal.show();
                    initializeModalFunctions();
                    initializeLightGallery();
                    initializeScrollNavbar();
                    /*const urlParams = new URLSearchParams(window.location.search);
                    urlParams.set('property', propertyId);
                    history.pushState(null, null, `${window.location.pathname}?${urlParams.toString()}`);*/
                    const currentPath = window.location.pathname;
                    const endWithSlash = currentPath.endsWith('/');
                    const newUrl = `${currentPath}${endWithSlash ? '' : '/'}${propertyId}${window.location.search}`;
                    history.pushState(null, null, newUrl);
                })
                .catch(error => {
                    console.error('Failed to load property details:', error);
                    alert('Failed to load property details. Please try again.');
                });
        });
    });
// Check if the URL contains the 'property' parameter and open the modal
    const urlParams = new URLSearchParams(window.location.search);
    const propertyId = prop_id; /*urlParams.get('property');*/
    if (propertyId) {
        fetch(`/property/${propertyId}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.text();
            })
            .then(data => {
                document.getElementById('propertyModalContent').innerHTML = data;
                var propertyModal = new bootstrap.Modal(document.getElementById('propertyModal'), {
                    keyboard: false
                });
                propertyModal.show();
                initializeModalFunctions();
                initializeLightGallery();
                initializeScrollNavbar();
            })
            .catch(error => {
                console.error('Failed to load property details:', error);
                alert('Failed to load property details. Please try again.');
            });
    }

    document.getElementById('propertyModal').addEventListener('hidden.bs.modal', function() {
        document.getElementById('propertyModalContent').innerHTML = '';

        // Remove the last part of the pathname
        const currentPath = window.location.pathname;
        let newPath = currentPath.substring(0, currentPath.lastIndexOf('/'));
        if(newPath === '')
        {
            newPath = '/';
        }
        // Update the URL
        history.pushState(null, null, `${newPath}${window.location.search}`);
    });
});
$(document).ready(function() {
    $('.facility-toggle').click(function() {
        var target = $(this).data('toggle-target');
        $(target).slideToggle();

        $(this).find('.toggle-icon').toggleClass('expanded collapsed');
    });
});

$(document).ready(function() {
    function matchHeight() {
        var mapHeight = $('#map').height();
        $('#filterAccordion').height(mapHeight);
    }

    matchHeight();
    $(window).resize(function() {
        matchHeight();
    });
});

/*MODAAAAAAALLLLLLL*/
/*MODAAAAAAALLLLLLL*/
/*MODAAAAAAALLLLLLL*/
/*MODAAAAAAALLLLLLL*/
/*MODAAAAAAALLLLLLL*/
/*MODAAAAAAALLLLLLL*/

export function initializeModalFunctions() {
    // Carousel thumbnails
    const thumbnails = document.querySelectorAll('.carousel-thumbnail');
    thumbnails.forEach(thumbnail => {
        thumbnail.addEventListener('click', function() {
            thumbnails.forEach(th => th.classList.remove('active'));
            this.classList.add('active');
        });
    });

    // Show more facilities button
    const showMoreButton = document.querySelector('.show-more-button-facilities');
    if (showMoreButton) {
        showMoreButton.addEventListener('click', function(e) {
            e.preventDefault();
            document.querySelectorAll('.facility-item.d-none').forEach(function(element) {
                element.classList.remove('d-none');
            });
            this.style.display = 'none';
        });
    }

    // Facility toggle
    $('.facility-toggle').click(function() {
        var target = $(this).data('toggle-target');
        $(target).slideToggle();

        // Toggle the icon class for rotation
        $(this).find('.toggle-icon').toggleClass('expanded collapsed');
    });

    // Initialize calculator
    if (typeof initializeCalculator === 'function') {
        initializeCalculator();
    }

    // Initialize modal map
    if (typeof initModalMap === 'function') {
        initModalMap();
    }

    // Initialize loading
    if (typeof initializeLoading === 'function') {
        initializeLoading();
    }
}

// Ensure Google Maps script is loaded
document.addEventListener('DOMContentLoaded', function() {
    console.log('DOMContentLoaded event firesd');

    if (!document.querySelector('script[src*="maps.googleapis.com/maps/api/js"]')) {
        const mapsApiKey = document.getElementById('map_api_key').value;
        const googleMapsScript = document.createElement('script');
        googleMapsScript.src = 'https://maps.googleapis.com/maps/api/js?key=' + mapsApiKey + '&callback=initModalMap';
        googleMapsScript.async = true;
        googleMapsScript.defer = true;
        document.head.appendChild(googleMapsScript);
    } else {
        initModalMap();
    }
});

function initializeLoading(){
    const generatePdfBtn = document.getElementById('generate-pdf-btn');
    const loadingSpinner = document.getElementById('loading-spinner');

    if (generatePdfBtn) {
        generatePdfBtn.addEventListener('click', function(event) {
            event.preventDefault(); // Prevent immediate navigation
            if (loadingSpinner) {
                loadingSpinner.style.display = 'flex'; // Show the loading spinner
            }
            // Redirect after a short delay
            setTimeout(function() {
                window.location.href = generatePdfBtn.href;
            }, 200); // 200ms delay for smoother UX
        });
    }
}

function initializeCalculator() {
    const totalAmountSlider = document.getElementById('totalAmountSlider');
    const totalAmount = document.getElementById('totalAmount');
    const advanceAmountSlider = document.getElementById('advanceAmountSlider');
    const advanceAmount = document.getElementById('advanceAmount');
    const loanPeriodSlider = document.getElementById('loanPeriodSlider');
    const loanPeriod = document.getElementById('loanPeriod');
    const interestRateSlider = document.getElementById('interestRateSlider');
    const interestRate = document.getElementById('interestRate');

    function updateValues() {
        totalAmount.value = totalAmountSlider?.value;
        advanceAmount.value = advanceAmountSlider?.value;
        loanPeriod.value = loanPeriodSlider?.value;
        interestRate.value = interestRateSlider?.value; // Sync the interest rate input with the slider

        calculateLoan();
    }

    function calculateLoan() {
        const totalAmountValue = parseFloat(totalAmount?.value);
        const advanceAmountValue = parseFloat(advanceAmount?.value);
        const loanPeriodValue = parseInt(loanPeriod?.value);
        const interestRateValue = parseFloat(interestRate?.value); // Use the correct interest rate value

        const loanAmountValue = totalAmountValue - (totalAmountValue * (advanceAmountValue / 100));
        const monthlyInterestRate = interestRateValue / 100 / 12;
        const numberOfPayments = loanPeriodValue;
        const monthlyPaymentValue = (loanAmountValue * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));

        if (isNaN(monthlyPaymentValue) || monthlyPaymentValue <= 0) {
            const loanAmount = document.getElementById('loanAmount');
            if (loanAmount) loanAmount.innerText = 'Error';
            const displayInterestRate = document.getElementById('displayInterestRate');
            if (displayInterestRate) displayInterestRate.innerText = 'Error';
            const monthlyPayment = document.getElementById('monthlyPayment');
            if (monthlyPayment) monthlyPayment.innerText = 'Error';
        } else {
            document.getElementById('loanAmount').innerText = loanAmountValue.toFixed(2) + ' €';
            document.getElementById('displayInterestRate').innerText = interestRateValue.toFixed(2) + '%';
            document.getElementById('monthlyPayment').innerText = monthlyPaymentValue.toFixed(2) + ' €';
        }
    }

    totalAmountSlider?.addEventListener('input', updateValues);
    totalAmount?.addEventListener('input', updateValues);
    advanceAmountSlider?.addEventListener('input', updateValues);
    advanceAmount?.addEventListener('input', updateValues);
    loanPeriodSlider?.addEventListener('input', updateValues);
    loanPeriod?.addEventListener('input', updateValues);
    interestRateSlider?.addEventListener('input', updateValues);
    interestRate?.addEventListener('input', updateValues);

    calculateLoan();
}

document.addEventListener('DOMContentLoaded', initializeCalculator);


window.initializeLightGallery = initializeLightGallery;

function initializeLightGallery() {
    const container = document.querySelector('#bootstrap-image-gallery');
    if (container) {
        lightGallery(container, {
            selector: '.lg-item',
            plugins: [lgThumbnail, lgZoom],
            download: false,
            thumbnail: true,
            escKey: false,
            mode: 'lg-fade',
            speed: 200,
            onCloseAfter: () => {
                document.body.classList.add('modal-open');
            },
            onAfterOpen: () => {
                document.addEventListener('keydown', function(event) {
                    if (event.key === 'Escape') {
                        alert(2)
                        event.preventDefault();
                    }
                });
            }
        });
    }
}

// Initialize LightGallery on DOMContentLoaded
document.addEventListener('DOMContentLoaded', function() {
    initializeLightGallery();
});

// Re-initialize LightGallery every time the modal content is loaded via AJAX
document.addEventListener('ajaxComplete', function() {
    initializeLightGallery();
});

function initializeScrollNavbar() {
    const scrollContainer = document.querySelector('.col-lg-6.col-md-6.col-sm-12.px-3');

    document.querySelectorAll('#navbarnav #navlink').forEach(anchor => {
        anchor.addEventListener('click', function(e) {
            e.preventDefault();
            const targetId = this.getAttribute('href').substring(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                scrollContainer.scrollTo({
                    top: targetElement.offsetTop - 160,
                    behavior: 'smooth'
                });
            }
        });
    });
}

function copyLink(link) {
    navigator.clipboard.writeText(link).then(() => {
        alert('Link copied to clipboard');
    }).catch(err => {
        console.error('Failed to copy link: ', err);
    });
}
document.addEventListener('DOMContentLoaded', function () {
    function initializeFavoriteButtons() {
        document.querySelectorAll('.favorite-btn').forEach(button => {
            button.removeEventListener('click', handleFavoriteButtonClick);
            button.addEventListener('click', handleFavoriteButtonClick);
        });
    }

    async function handleFavoriteButtonClick(e) {
        e.stopPropagation();
        const propertyId = this.getAttribute('data-property-id');
        const icon = this.querySelector('i');
        console.log('here')
        console.log(JSON.stringify({property_id: propertyId}))
        await fetch(`/favorites/toggle/${propertyId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify({ property_id: propertyId })
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data.status === 'added') {
                    icon.classList.remove('fa-regular');
                    icon.classList.add('remax-red');
                } else {
                    icon.classList.remove('remax-red');
                    icon.classList.add('fa-regular');
                }
                document.querySelectorAll(`.favorite-btn[data-property-id="${propertyId}"] i`).forEach(listIcon => {
                    if (listIcon !== icon) {
                        if (data.status === 'added') {
                            listIcon.classList.remove('fa-regular');
                            listIcon.classList.add('remax-red');/*'fa-heart', */
                        } else {
                            listIcon.classList.remove( 'remax-red');/*'fa-heart',*/
                            listIcon.classList.add('fa-regular');
                        }
                    }
                });
            });
    }

    initializeFavoriteButtons();

    document.getElementById('propertyModal').addEventListener('show.bs.modal', function () {
        initializeFavoriteButtons();
    });
});
